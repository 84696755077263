import React from "react";

const OurUsp = () => {
  return (
    <section
      className="services position-re call-action section-padding sub-bg bg-img"
      style={{ backgroundImage: `url(/img/patrn.svg)` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Our USP
              </h6>
              <h3 className="wow color-font">For Client Satisfaction</h3>
              <p>
                Overall, our USP is our commitment to delivering customized
                digital solutions that help our clients succeed in the
                ever-evolving digital world. If you're looking for a web and
                software agency that you can trust, choose{" "}
                <a href="https://evolvan.com/">
                  <i>Evolvan</i>
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-4 wow fadeInUp d-flex align-items-end"
            data-wow-delay=".3s"
          >
            <div className="step-item xtop">
              <h3 className="icon usp-icon">10</h3>
              <h6>10 x Proven Expertise</h6>
              <p>
                We have a team of experienced developers, designers, and digital
                marketing experts who have a deep understanding in the digital
                world.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
            <div className="step-item xcolor">
              <h3 className="icon usp-icon">20</h3>
              <h6>20 x Innovative Solutions</h6>
              <p>
                We believe that innovation is essential to staying ahead in the
                digital world. We are constantly exploring new technologies and
                approaches.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".9s">
            <div className="step-item xbottom">
              <h3 className="icon usp-icon">30</h3>
              <h6>30 x Timely Delivery</h6>
              <p>
                We understand the importance of timely delivery and aim to
                deliver our high-quality work that meets and exceeds our
                clients' expectations
                {/* and time and within budget. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurUsp;
