import React from "react";

const industries = () => {
  return (
    <>
      <section className="services box lficon section-padding position-re industry">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h3 className="wow color-font">Industries we serve</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 wow fadeInLeft">
              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-utensils"></span>
                </div>
                <div className="cont">
                  <h6>Resturants</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-sign-hanging"></span>
                </div>
                <div className="cont">
                  <h6>Real Estate</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-cart-shopping"></span>
                </div>
                <div className="cont">
                  <h6>E-Commerce</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-school"></span>
                </div>
                <div className="cont">
                  <h6>Education</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-laptop-file"></span>
                </div>
                <div className="cont">
                  <h6>Saas</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-newspaper"></span>
                </div>
                <div className="cont">
                  <h6>News</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-dice"></span>
                </div>
                <div className="cont">
                  <h6>Entertainment</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-truck-fast"></span>
                </div>
                <div className="cont">
                  <h6>Logistics</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-plane"></span>
                </div>
                <div className="cont">
                  <h6>Travel</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-sack-dollar"></span>
                </div>
                <div className="cont">
                  <h6>Finance</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-bolt"></span>
                </div>
                <div className="cont">
                  <h6>EV</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-wheat-awn"></span>
                </div>
                <div className="cont">
                  <h6>Agriculture</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-calendar-days"></span>
                </div>
                <div className="cont">
                  <h6>Events</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-volleyball"></span>
                </div>
                <div className="cont">
                  <h6>Sports</h6>
                </div>
              </div>

              <div className="industry item-box no-curve">
                <div>
                  <span className="color-font fa-solid fa-hand-holding-medical"></span>
                </div>
                <div className="cont">
                  <h6>Health Care </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default industries;
