import React, { useState, useRef,useEffect } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import Services5 from "components/Services5/services5";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Blogs2 from "components/blogs/Blogs2/blogs2";
import SContactForm from "components/s-contact-form/s-contact-form";
import DarkTheme from "layouts/Dark";
import MinimalArea from "components/Minimal-Area/minimal-area";
import Numbers from "components/Numbers/numbers";
import OurUsp from "components/Our-usp/our-usp";
import IntroWithSlider from "components/Intro-with-slider/intro-with-slider";
import WorksStyle2 from "components/Works-style2/works-style2";
import { GetProjectsData } from "components/ApiServices/GetProjectsData";
import anime from "animejs/lib/anime.es.js";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import SEO from "components/seo/seo";
import GTM from "components/GTM/gtm";
import Faq from "components/Faq/faq";
import { GetServicesData } from "components/ApiServices/GetServicesData";
import Industries from "components/Industries/industries";
import LoadingScreen from "components/Loading-Screen";

const Homepage = (props) => {
  const ProjectsData = GetProjectsData();

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const fixedSlider = React.useRef(null);
  const MainContent = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    
    const animateElements = () => {
      const elements = document.querySelectorAll(".animated-element");
      elements.forEach((element) => {
        anime({
          targets: element,
          opacity: 1,
          translateY: 0,
          duration: 1000,
          easing: "easeOutQuad",
          delay: anime.stagger(1000),
        });
      });
    };

    setTimeout(animateElements, 2500);

    
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }

    setInterval(() => {
      if (fixedSlider.current) {
        var slidHeight = fixedSlider.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    setTimeout(() => {
      // code svg
      anime({
        targets: ".slide-code01",
        translateX: 25,
        direction: "alternate",
        loop: true,
        easing: "linear",
        duration: 2000,
      });

      // js svg
      anime({
        targets: ".slide-js",
        translateX: 25,
        direction: "alternate",
        loop: true,
        easing: "easeInOutSine",
        duration: 2500,
      });

      // girl
      anime({
        targets: ".slide-mainobj",
        translateX: 2,
        direction: "alternate",
        loop: true,
        easing: "easeInQuad",
        duration: 2500,
      });

      // girl laptop
      anime({
        targets: ".st6",
        duration: 1000,
        loop: true,
        opacity: [0, 1],
        fill: ["#ed5471", "#2ab7ea"],
        easing: "linear",
        direction: "alternate",
      });

      // waves animation start
      const wave1 =
          "M0 108.306L50 114.323C100 120.34 200 132.374 300 168.476C400 204.578 500 264.749 600 246.698C700 228.647 800 132.374 900 108.306C1000 84.2382 1100 132.374 1150 156.442L1200 180.51V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V108.306Z",
        wave2 =
          "M0 250L50 244.048C100 238.095 200 226.19 300 226.19C400 226.19 500 238.095 600 232.143C700 226.19 800 202.381 900 196.429C1000 190.476 1100 202.381 1150 208.333L1200 214.286V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V250Z",
        wave3 =
          "M0 250L50 238.095C100 226.19 200 202.381 300 166.667C400 130.952 500 83.3333 600 101.19C700 119.048 800 202.381 900 214.286C1000 226.19 1100 166.667 1150 136.905L1200 107.143V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V250Z",
        wave4 =
          "M0 125L50 111.111C100 97.2222 200 69.4444 300 97.2222C400 125 500 208.333 600 236.111C700 263.889 800 236.111 900 229.167C1000 222.222 1100 236.111 1150 243.056L1200 250V0H1150C1100 0 1000 0 900 0C800 0 700 0 600 0C500 0 400 0 300 0C200 0 100 0 50 0H0V125Z";

      anime({
        targets: ".wave-top > path",
        easing: "linear",
        duration: 7500,
        loop: true,
        d: [
          { value: [wave1, wave2] },
          { value: wave3 },
          { value: wave4 },
          { value: wave1 },
        ],
      });
      // waves animation end

      anime({
        targets: ".slide-right1",
        translateX: 22,
        direction: "alternate",
        loop: true,
        easing: "easeInOutSine",
        duration: 2200,
      });

      anime({
        targets: ".slide-right2",
        translateX: 20,
        direction: "alternate",
        loop: true,
        easing: "easeInOutSine",
        duration: 2000,
      });

      anime({
        targets: ".slide-right3",
        translateX: 5,
        direction: "alternate",
        loop: true,
        easing: "easeInOutSine",
        duration: 2000,
      });
    }, 2000);

    
  }, [fixedSlider, MainContent, navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };


  return (
    <GTM>
      <DarkTheme themeValue={theme}>
        <LoadingScreen/>
        <Navbar
          nr={navbarRef}
          lr={logoRef}
          theme={theme}
          themeModeChange={themeModeChange}
        />
        <ParticalsOverlay />
        <IntroWithSlider sliderRef={fixedSlider} />
        <div ref={MainContent} className="main-content">
          <div className="line left"></div>
          <MinimalArea />
          <div className="line right"></div>
          <OurUsp />
          <div className="line left"></div>
          <Services5 />
          <div className="line right"></div>
          <WorksStyle2
            grid={3}
            filterPosition="center"
            projectData={ProjectsData}
            propData={props}
            hideFilter
          />
          <div className="line left"></div>
          <Numbers />
          <div className="line right"></div>
          <FullTestimonials showHead={true} />
          <div className="line left"></div>
          <Industries />
          <div className="line right"></div>
          <Faq />
          <div className="line left"></div>
          <Blogs2 />
          <div className="line right"></div>
          <SContactForm noLine />
          <div className="line left"></div>
          <Footer theme={theme} />
        </div>
      </DarkTheme>
    </GTM>
  );
};

export const Head = () => {
  return (
    <>
      <SEO
        title={`Web Development & Digital Transformation Agency in India | Evolvan`}
        description={`Boost your online presence with the Best Web Development & Digital Transformation  agency. Drive traffic, increase conversions, and grow your business with our expert strategies.`}
      />
    </>
  );
};

export default Homepage;
